var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row match-height" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
      [
        _c(
          "vx-card",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                return _vm.openManageBranches()
              },
            },
          },
          [
            _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
              _c("img", {
                staticClass: "responsive card-img-top",
                attrs: {
                  src: "https://static.travelify.io/tools/branches.png",
                },
                on: { error: _vm.setAltImgForThumbnail },
              }),
            ]),
            _c("div", { staticClass: "flex flex-wrap justify-start" }, [
              _c("h5", { staticClass: "mt-2" }, [_vm._v("Manage Branches")]),
            ]),
            _c("p", { staticClass: "text-grey mt-4" }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
      [
        _c(
          "vx-card",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                return _vm.openManageDeliveryFees()
              },
            },
          },
          [
            _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
              _c("img", {
                staticClass: "responsive card-img-top",
                attrs: {
                  src: "https://static.travelify.io/tools/delivery.png",
                },
                on: { error: _vm.setAltImgForThumbnail },
              }),
            ]),
            _c("div", { staticClass: "flex flex-wrap justify-start" }, [
              _c("h5", { staticClass: "mt-2" }, [
                _vm._v("Manage Delivery Fees"),
              ]),
            ]),
            _c("p", { staticClass: "text-grey mt-4" }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
      [
        _c(
          "vx-card",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                return _vm.openManageCurrencies()
              },
            },
          },
          [
            _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
              _c("img", {
                staticClass: "responsive card-img-top",
                attrs: {
                  src: "https://static.travelify.io/tools/currencies.png",
                },
                on: { error: _vm.setAltImgForThumbnail },
              }),
            ]),
            _c("div", { staticClass: "flex flex-wrap justify-start" }, [
              _c("h5", { staticClass: "mt-2" }, [_vm._v("Manage Currencies")]),
            ]),
            _c("p", { staticClass: "text-grey mt-4" }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
      [
        _c(
          "vx-card",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                return _vm.openManageExchangeRates()
              },
            },
          },
          [
            _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
              _c("img", {
                staticClass: "responsive card-img-top",
                attrs: {
                  src: "https://static.travelify.io/tools/exchange.png",
                },
                on: { error: _vm.setAltImgForThumbnail },
              }),
            ]),
            _c("div", { staticClass: "flex flex-wrap justify-start" }, [
              _c("h5", { staticClass: "mt-2" }, [
                _vm._v("Manage Exchange Rates"),
              ]),
            ]),
            _c("p", { staticClass: "text-grey mt-4" }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }